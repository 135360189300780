import { Distributor_transaction } from "../axios";

export const CreteDistributor_transaction = async (data) => {
  var CreteDistributor_transaction = await Distributor_transaction.post(
    `/create`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteDistributor_transaction;
};

export const viewByUserDistributor_transaction = async (data) => {
  var viewByUserDistributor_transaction = await Distributor_transaction.post(
    `/viewByUser`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByUserDistributor_transaction;
};

export const viewByDateDistributor_transaction = async (data) => {
  var viewByDateDistributor_transaction = await Distributor_transaction.post(
    `/viewByDate`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByDateDistributor_transaction;
};

export const viewAllDistributor_transaction = async (data) => {
  var viewAllDistributor_transaction = await Distributor_transaction.post(
    `/viewAll`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllDistributor_transaction;
};

export const viewByorderidDistributor_transaction = async (data) => {
  var viewByorderidDistributor_transaction = await Distributor_transaction.post(
    `/viewByorderid`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByorderidDistributor_transaction;
};
