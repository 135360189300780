/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./App.css";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import { storeAction } from "./Store/Store";
import { useDispatch } from "react-redux";
import { viewByidDistributor } from "./Api/distributor";

function App() {
  const dispatch = useDispatch();

  var [userid, setuserid] = useState(null);
  useEffect(() => {
    var userid = sessionStorage.getItem("user_id");
    setuserid(userid);
    getalldata();
  }, [userid]);
  const getalldata = async () => {
    var userid = sessionStorage.getItem("user_id");
    if (userid !== null) {
      var viewuser = await viewByidDistributor({ id: userid });
      if (viewuser.length !== 0) {
        dispatch(
          storeAction.pg_balanceHandler({ pg_balance: viewuser[0].pg_balance })
        );
        dispatch(storeAction.balanceHandler({ balance: viewuser[0].balance }));
      }
    }
  };

  return <>{userid === null ? <Login /> : <Layout />}</>;
}

export default App;
