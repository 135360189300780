import { Distributor_payout } from "../axios";

export const CreteDistributor_payout = async (data) => {
  var CreteDistributor_payout = await Distributor_payout.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteDistributor_payout;
};

export const updateDistributor_payout = async (data) => {
  var updateDistributor_payout = await Distributor_payout.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateDistributor_payout;
};

export const viewAllDistributor_payout = async (data) => {
  var viewAllDistributor_payout = await Distributor_payout.post(
    `/viewAll`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewAllDistributor_payout;
};


export const destroyDistributor_payout = async (data) => {
  var destroyDistributor_payout = await Distributor_payout.post(
    `/destroy`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyDistributor_payout;
};
