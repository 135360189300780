import { Order } from "../axios";

export const CreteToken = async (data) => {
  var CreteToken = await Order.post(`/cretetoken`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteToken;
};

export const CreteOrder = async (data) => {
  var CreteOrder = await Order.post(`/creteorder`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteOrder;
};

export const StatusOrder = async (data) => {
  var StatusOrder = await Order.post(`/statusorder`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return StatusOrder;
};

export const getbalanceOrder = async (data) => {
  var getbalanceOrder = await Order.post(`/getbalance`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return getbalanceOrder;
};
