import Header from "../components/Header";
import SidebarNav from "../components/SidebarNav";
import { Routes, Route } from "react-router-dom";
import User from "../components/User/User";
import Adduser from "../components/User/Adduser";
import Charges from "../components/User/Charges";
import EditUser from "../components/User/Edituser";
import PaymentTable from "../components/Payout";
import Transaction from "../components/Transaction";
import View from "../components/View";
import Dashboard from "../components/Dashboard";

const Layout = () => {
  return (
    <div className="">
      <div className="App overflow-y-hidden ">
        <Header />
        <div className="w-full min-h-[90vh] grid grid-cols-12 ">
          <div className="col-span-2 py-4 px-2 border-r">
            <SidebarNav />
          </div>
          <div className="col-span-10 h-screen px-5 ">
            <Routes>
              <Route path="/user" element={<User />} />
              <Route path="/adduser" element={<Adduser />} />
              <Route path="/charges" element={<Charges />} />
              <Route path="/editUser" element={<EditUser />} />
              <Route path="/payout" element={<PaymentTable />} />
              <Route path="/transaction" element={<Transaction />} />
              <Route path="/view" element={<View />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
