import { configureStore, createSlice } from "@reduxjs/toolkit";

const StoreSlice = createSlice({
  name: "Store",
  initialState: {
    pg_balance: 0,
    user_id: null,
    balance: 0,
    action: true,
  },
  reducers: {
    pg_balanceHandler(state, payload) {
      state.pg_balance = payload.payload.pg_balance;
    },
    useridHandler(state, payload) {
      state.user_id = payload.payload.user_id;
    },
    balanceHandler(state, payload) {
      state.balance = payload.payload.balance;
    },
    actionHandler(state, payload) {
      state.action = payload.payload.action;
    },
  },
});

export const storeAction = StoreSlice.actions;
const Store = configureStore(StoreSlice);
export default Store;
