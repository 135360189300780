/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Alluserdata } from "../Api/User";
import { viewByUserDistributor_transaction } from "../Api/distributor_transaction";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [userdatacount, setuserdatacount] = useState(0);
  const [transactiondatacount, settransactiondatacount] = useState(0);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var dis_id = sessionStorage.getItem("user_id");
    var userdata = await Alluserdata();
    if (userdata.length !== 0) {
      var checkdata = await userdata.filter((data) => {
        return data.dis_id == dis_id;
      });
      setuserdatacount(checkdata.length);
    }
    var alldata = await viewByUserDistributor_transaction({ id: dis_id });
    settransactiondatacount(alldata.length);
  };
  const balance = useSelector((store) => store.pg_balance);
  return (
    <div>
      <h1 className="text-start font-bold text-xl mt-5">Dashboard</h1>
      <div className="flex flex-col justify-center items-center gap-2 md:grid md:grid-cols-3 md:gap-4 mt-12">
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5">
          <p className="text-center text-black text-xl">No Of Users</p>
          <p className="text-center text-red-500 text-3xl">{userdatacount}</p>
        </div>
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5">
          <p className="text-center text-black text-xl">No Of Transaction</p>
          <p className="text-center text-red-500 text-3xl">
            {transactiondatacount}
          </p>
        </div>
        <div className="w-100 md:h-36 dashboardcard md:w-100 text-sm font-bold bg-white border-gray-50 shadow-md border shadow-gray-500/100 py-5">
          <p className="text-center text-black text-xl">Balance</p>
          <p className="text-center text-red-500 text-3xl">
            ₹ {parseFloat(balance).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
