import axios from "axios";

export const User = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/user`,
});

export const Smtp = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/smtp`,
});

export const Distributor = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor`,
});

export const Order = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/order`,
});

export const Payout = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/payout`,
});

export const Transaction = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/transaction`,
});

export const Distributor_payout = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor_payout`,
});

export const Distributor_transaction = axios.create({
  baseURL: `${process.env.REACT_APP_LOCAL_HOST_URL}/distributor_transaction`,
});
