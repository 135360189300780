/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import { BiChevronDown, BiUserCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
import { CgLogOff } from "react-icons/cg";
import { useSelector } from "react-redux";

const SidebarNav = () => {
  const balancevalue = useSelector((store) => store.balance);
  const pg_balancevalue = useSelector((store) => store.pg_balance);
  const [toggle, setToggle] = useState(false);
  const [profile, setprofile] = useState(false);
  const [balance, setbalance] = useState(0);
  const [bg_balance, setbg_balance] = useState(0);
  const logoutbtn = () => {
    sessionStorage.removeItem("user_id");
    window.location.reload();
  };
  useEffect(() => {
    getalldata();
  }, [balancevalue, pg_balancevalue]);
  const getalldata = async () => {
    setbalance(balancevalue);
    setbg_balance(pg_balancevalue);
  };

  return (
    <>
      <div className="flex flex-col items-center my-8">
        <ul className="flex flex-col font-medium gap-5">
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <RectangleStackIcon className="h-6" />
            Dashboard
          </li>

          <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <BiUserCircle className="h-6 w-6" />
              User
            </div>
            <div className=" items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setToggle(!toggle)}
              />
            </div>
          </li>

          {toggle && (
            <ul className="top-5 cursor-pointer ml-7">
              <li
                className="text-left"
                onClick={() => window.location.replace("/adduser")}
              >
                Add User
              </li>
              <li
                className="text-left mt-4"
                onClick={() => window.location.replace("/user")}
              >
                Users
              </li>
            </ul>
          )}
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              window.location.replace("/payout");
            }}
          >
            <BanknotesIcon className="h-6" />
            Payout
          </li>
          <li
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              window.location.replace("/transaction");
            }}
          >
            <ArrowsRightLeftIcon className="h-6" />
            Transaction
          </li>
          {/* <li className="flex gap-2 justify-between items-center cursor-pointer relative">
            <div className="flex gap-2 justify-center">
              <UserIcon className="h-6 w-6" />
              Profile
            </div>
            <div className="items-center ">
              <BiChevronDown
                className="w-4 h-4 bg-blue-400 text-white rounded-full  "
                onClick={() => setprofile(!profile)}
              />
            </div>
          </li>
          {profile && (
            <ul className="top-0 cursor-pointer ml-7">
              <li
                className="text-left"
                onClick={() => window.location.replace("/passwordchange")}
              >
                Change Password
              </li>
            </ul>
          )} */}
          <li className="flex gap-2 cursor-pointer" onClick={logoutbtn}>
            <CgLogOff className="h-6 w-6" />
            Log out
          </li>
        </ul>
      </div>
      <div className="flex gap-2 cursor-pointer ml-5 footerdiv">
        <ul className="flex flex-col justify-end font-medium ">
          {/* <li className="flex ">
            Balance :{" "}
            <span className="pl-1">₹ {parseFloat(balance).toFixed(2)}</span>{" "}
          </li>
          <li className="flex ">
            PG Balance :{" "}
            <span className="pl-1">₹ {parseFloat(bg_balance).toFixed(2)}</span>{" "}
          </li> */}
          <li className="flex ">
            Balance :{" "}
            <span className="pl-1">₹ {parseFloat(bg_balance).toFixed(2)}</span>{" "}
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarNav;
