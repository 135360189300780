import { User } from "../axios";

export const CreteUser = async (data) => {
  var CreteUser = await User.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return CreteUser;
};

export const LoginUser = async (data) => {
  var LoginUser = await User.post(`/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return LoginUser;
};

export const viewByidUser = async (data) => {
  var viewByidUser = await User.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidUser;
};

export const Alluserdata = async (data) => {
  var Alluserdata = await User.get(`/userdata`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return Alluserdata;
};

export const updateuserdata = async (data) => {
  var updateuserdata = await User.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateuserdata;
};

export const destroyuserdata = async (data) => {
  var destroyuserdata = await User.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyuserdata;
};
