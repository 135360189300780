/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Alluserdata, destroyuserdata, updateuserdata } from "../../Api/User";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const User = () => {
  const [userdata, setuserdata] = useState([]);
  const [alluserdata, setalluserdata] = useState([]);
  useEffect(() => {
    getalldata();
  }, []);
  const getalldata = async () => {
    var dis_id = sessionStorage.getItem("user_id");
    var userdata = await Alluserdata();
    if (userdata.length !== 0) {
      var checkdata = await userdata.filter((data) => {
        return data.dis_id == dis_id;
      });
      setuserdata(checkdata);
      setalluserdata(checkdata);
    }
   
  };
  const changestatus = async (data) => {
    var status = document.getElementById(`status${data.id}`).value;
    if (status.length !== 0) {
      var newdata = {
        id: data.id,
        status: status,
      };
      var updatedata = await updateuserdata(newdata);
      if (updatedata === "Updated Successfully") {
        toast.success("User Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getalldata();
      }
    } else {
      toast.error("Please Select Other Option...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
  };
  const searchitem = async (e) => {
    if (e.length !== 0) {
      if (alluserdata.length !== 0) {
        var filterdata = [];
        for (var i = 0; i < alluserdata.length; i++) {
          if (alluserdata[i].name.toLowerCase().match(e.toLowerCase())) {
            filterdata.push(alluserdata[i]);
          }
        }
        setuserdata(filterdata);
      }
    } else {
      setuserdata(alluserdata);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const currentPost = userdata.slice(indexofFirstPost, indexofLastPost);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userdata.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const deletebtn = async (e) => {
    await destroyuserdata({ id: e });
    window.location.reload();
  };
  const editbtn = (e) => {
    sessionStorage.setItem("edituser", e);
    window.location.replace(`/editUser`);
  };
  const vewcharges = (e) => {
    sessionStorage.setItem("edituser", e);
    window.location.replace(`/charges`);
  };
  return (
    <div>
      <div className="flex justify-between py-4">
        <h1 className="text-start font-bold text-xl">Users List</h1>
        <Button
          variant="contained"
          size="small"
          color="info"
          onClick={() => window.location.replace("/adduser")}
        >
          Add User
        </Button>
      </div>
      <div className="flex justify-between py-4">
        <input
          placeholder="Seach User"
          className="border p-2 w-1/3 border-black rounded"
          onChange={(e) => {
            searchitem(e.target.value);
          }}
        />
      </div>
      <div className="my-8" style={{ height: 700, width: "100%" }}>
        <TableContainer component={Paper} className="border border-black">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-black text-white">
              <TableRow className="border border-black">
                <TableCell>
                  {" "}
                  <text className="font-bold text-white ">#</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">Name</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">Company Name</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">Email</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">PG Balance</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">Balance</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">Status</text>
                </TableCell>
                <TableCell align="left">
                  <text className="font-bold text-white">Status Action</text>
                </TableCell>

                <TableCell align="center">
                  <text className="font-bold text-white">Charge</text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white">Edit</text>
                </TableCell>
                <TableCell align="center">
                  <text className="font-bold text-white">Remove</text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="border-2">
              {currentPost.length !== 0
                ? currentPost.map((data, index) => (
                    <TableRow className="border border-black" key={index}>
                      <TableCell className="border border-black">
                        {index + 1}
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        {data.name}
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        {data.companyname}
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        {data.email}
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        {parseFloat(data.pg_balance).toFixed(2)}
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        {parseFloat(data.balance).toFixed(2)}
                      </TableCell>
                      <TableCell className="border border-black text-xs" align="left">
                        {data.status}
                      </TableCell>
                      <TableCell className="border border-black" align="left">
                        <select
                          onChange={() => {
                            changestatus(data);
                          }}
                          id={`status${data.id}`}
                        >
                          <option value="">Select</option>
                          <option value="De-Active">De-Active</option>
                          <option value="Block">Block</option>
                          <option value="Active">Active</option>
                        </select>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Button
                          variant="contained"
                          size="small"
                          className=" items-start "
                          color="success"
                          onClick={() => {
                            vewcharges(data.id);
                          }}
                        >
                          Charges
                        </Button>
                      </TableCell>
                      <TableCell
                        className="border border-black flex justify-between items-center"
                        align="left"
                      >
                        <Button
                          variant="contained"
                          size="small"
                          className="m-2 items-start "
                          color="primary"
                          onClick={() => {
                            editbtn(data.id);
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell
                        className="border border-black flex justify-between items-center"
                        align="left"
                      >
                        <Button
                          variant="contained"
                          size="small"
                          className="p-2 items-start "
                          color="error"
                          onClick={() => {
                            deletebtn(data.id);
                          }}
                        >
                          remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {pageNumbers.length > 1 ? (
          <div className="row mt-5">
            <ul className="pagination">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={() => setCurrentPage(number)}
                    className="page-link"
                  >
                    {number}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </div>
  );
};

export default User;
