/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { firebase } from "../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateuserdata } from "../../Api/User";
import { viewByidUser } from "../../Api/User";

const Adduser = () => {
  var id = sessionStorage.getItem("edituser");
  const [userdata, setuserdata] = useState({
    name: "",
    companyname: "",
    company_address: "",
    email: "",
    phone: "",
    pan_no: "",
    aadhar_no: "",
    gst: "",
    address_url: "",
    id_url: "",
    address: "",
  });
  const [errorlist, seterrorlist] = useState(null);
  const [clicked, setclicked] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setuserdata((values) => ({ ...values, [name]: value }));
  };
  const getaddressurl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("logo/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl1 = await file13;
    if (imgurl1 !== null) {
      toast.success("Image uploaded...", {
        autoClose: 5000,
        transition: Slide,
      });
      setuserdata((values) => ({ ...values, address_url: imgurl1 }));
    }
  };
  const getidurl = async (e) => {
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref("logo/" + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          //img download link ah ketakiradhu
          setTimeout(() => resolve(url), 1000);
        });
      });
    });
    var imgurl1 = await file13;
    if (imgurl1 !== null) {
      toast.success("Image uploaded...", {
        autoClose: 5000,
        transition: Slide,
      });
      setuserdata((values) => ({ ...values, id_url: imgurl1 }));
    }
  };
  const submitbtn = async () => {
    userdata["id"] = id;
    var updatedata = await updateuserdata(userdata);
    if (updatedata === "Updated Successfully") {
      toast.success("User Updated Successfully...", {
        autoClose: 5000,
        transition: Slide,
      });
      setTimeout(() => {
        window.location.replace("/user");
      }, 2000);
    }
  };
  useEffect(() => {
    getuserdata();
  }, [id]);
  const getuserdata = async () => {
    if (id !== null) {
      var viewuser = await viewByidUser({ id: id });
      if (viewuser.length !== 0) {
        setuserdata({
          name: viewuser[0].name,
          companyname: viewuser[0].companyname,
          company_address: viewuser[0].company_address,
          email: viewuser[0].email,
          phone: viewuser[0].phone,
          pan_no: viewuser[0].pan_no,
          aadhar_no: viewuser[0].aadhar_no,
          gst: viewuser[0].gst,
          address_url: viewuser[0].address_url,
          id_url: viewuser[0].id_url,
          address: viewuser[0].address,
        });
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between pt-4">
        <h1 className="text-start font-bold text-xl">Edit Users</h1>
      </div>
      <div className="pt-2">
        <Stack spacing={0.6} direction={"column"} className="  ">
          <label className="text-left font-semibold">Name </label>
          <input
            type="text"
            size="medium"
            placeholder="Name"
            name="name"
            className="w-96 border border-gray-600 p-2 rounded"
            onChange={handleChange}
            defaultValue={userdata.name}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.name}</div>
          ) : null}
          <label className="text-left font-semibold">Company Name</label>
          <input
            type="text"
            name="companyname"
            placeholder="Company Name"
            onChange={handleChange}
            defaultValue={userdata.companyname}
            className="w-96 border border-gray-600 p-2 rounded"
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">
              {errorlist.companyname}
            </div>
          ) : null}
          <label className="text-left font-semibold">Email </label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.email}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.email}</div>
          ) : null}
          <label className="text-left font-semibold">Phone </label>
          <input
            type="number"
            placeholder="Phone"
            name="phone"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.phone}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.phone}</div>
          ) : null}
          <label className="text-left font-semibold">PAN NO</label>
          <input
            type="text"
            placeholder="PAN NO"
            name="pan_no"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.pan_no}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.pan_no}</div>
          ) : null}
          <label className="text-left font-semibold">Aadhar NO</label>
          <input
            type="text"
            name="aadhar_no"
            placeholder="Aadhar NO"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.aadhar_no}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.aadhar_no}</div>
          ) : null}
          <label className="text-left font-semibold">GST NO</label>
          <input
            type="text"
            name="gst"
            placeholder="GST NO"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.gst}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.gst}</div>
          ) : null}
          <label className="text-left font-semibold">Address Proof</label>
          <input
            type="file"
            name="address_url"
            onChange={getaddressurl}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.address_url}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">
              {errorlist.address_url}
            </div>
          ) : null}
          <label className="text-left font-semibold">Id Proof</label>
          <input
            type="file"
            name="id_url"
            onChange={getidurl}
            className="w-96 border border-gray-600 p-2 rounded"
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.id_url}</div>
          ) : null}
          <label className="text-left font-semibold">Address </label>
          <textarea
            type="text"
            name="address"
            placeholder="Address"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.address}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">{errorlist.address}</div>
          ) : null}
          <label className="text-left font-semibold">Company Address</label>
          <textarea
            type="text"
            name="company_address"
            placeholder="company_address"
            onChange={handleChange}
            className="w-96 border border-gray-600 p-2 rounded"
            defaultValue={userdata.company_address}
          />
          {errorlist !== null ? (
            <div className="text-red-500 text-start">
              {errorlist.company_address}
            </div>
          ) : null}
        </Stack>
      </div>

      <div className="flex justify-start mt-5 mb-5 w-24">
        <Stack spacing={2}>
          {clicked === true ? (
            <button
              className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3"
              onClick={submitbtn}
            >
              Please Wait...
            </button>
          ) : (
            <button
              className="bg-blue-500 text-white font-bold rounded rounded text-md px-5 py-3"
              onClick={submitbtn}
            >
              Update
            </button>
          )}
        </Stack>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Adduser;
